<template>
    <div class="loading black flex flex-x flex-y" @click="showFun">
        <div v-if="login" class="overlay overlay-login" v-on:click.stop>
            <div v-if="codeFun()" class="flex-1">
                <p>扫码{{text}}</p>
                <div class="login-img">
                    <img :src="codeFun()"/>
                </div>
            </div>
            <div class="flex-1">
                <p>输入手机号码{{text}}</p>
                <input type="tel" placeholder="输入手机号码" v-model="tel"/>
                <p>或输入姓名{{birthday?'':'和生日'}}{{text}}</p>
                <input type="text" placeholder="输入姓名" v-model="name"/>
                <div class="birthday" v-if="!birthday" @click="()=>this.birth=true">
                    <span v-if="date" class="birthday-date">{{date.split(' ')[0]}}</span>
                    <span v-else>选择出生日期</span>
                </div>
                <button @click="telFun">提交</button>
            </div>
        </div>
        <div class="overlay overlay-max" v-on:click.stop v-else>
            <div v-if="codeFun()">
                <div class="overlay-whith">
                    <div class="overlay-align" v-if="show">
                        <div>唐古信息 & 泉脉科技</div>
                        <div>中医数智化解决方案</div>
                    </div>
                    <img :src="codeFun()"/>
                </div>
                <div v-if="!prescribe">
                    <div v-if="!url">
                        <div class="overlay-text">微信扫码登录</div>
                    </div>
                    <div v-else>
                        <div class="overlay-text">微信扫一扫</div>
                        <div class="overlay-text" v-if="url=='confirmAssess'">先扫码再检测</div>
                        <div class="overlay-text" v-else >扫码后看报告</div>
                    </div>
                </div>
                <div v-else>
                    <div class="overlay-text">用户扫码</div>
                    <div class="overlay-text size-min">用户需要用微信扫码登录 与该报告进行绑定</div>
                    <div class="skip" @click="()=>skipFun(true)">跳过</div>
                </div>
            </div>
            <div v-else class="overlay-top">
                <div class="overlay-text">请联系管理人员绑定公众号</div>
            </div>
        </div>
        <div class="picker-bottom" v-on:click.stop v-if="birth">
            <van-datetime-picker
                v-model="currentDate"
                type="date"
                :formatter="formatter"
                title="出生年月日"
                :min-date="minDate"
                :max-date="maxDate"
                @cancel="()=>this.birth=false"
                @confirm="confirmFun"
            />
        </div>
    </div>
</template>

<script>
    import { RandomString , makecode } from "@/utils";
    import { mapActions } from 'vuex';
    import { audioPaly } from "@/utils";
    import { jiceover } from "@/utils/audio";
    import domain from "@/utils/config";
    import { Toast } from 'vant';

    export default {
        name: "popup",
        props: ["url","showFun","token","callback","audio","prescribe","skipFun","query","login","birthday","text"],
        data(){ 
            return {
                uuid:RandomString(16),
                birth:false,
                date:'',
                name:'',
                tel:'',
                maxDate:new Date(),
                minDate:new Date(1900, 0, 1),
                currentDate:new Date(1970, 0, 1),
                show:false
            }
        },
        created: function () {
            this.mainFun();
            let equipment = JSON.parse(sessionStorage.getItem('equipment'));
            if(equipment){
                let macs = ['WJ7XWG','QFC6SB'];
                macs.map((mac)=>{
                    if(mac==equipment.equipment_code){
                        this.show = true
                    }
                })
            }
        },
        destroyed: function () {
            this.closeFun();
        },
        computed:{},
        methods:{
            codeFun(){
                let equipment = JSON.parse(sessionStorage.getItem('equipment'));
                if(equipment && equipment.appid){
                    let url = `https://${equipment.appid}${domain}?Code=${equipment.equipment_code}&Uuid=${this.uuid}&source=2`;
                    return makecode(url);
                }else{
                    return false;
                }
            },
            confirmFun(value){
                this.date = this.timeSubmit(value);
                this.birth = false;
            },
            timeSubmit(value){
                let date = new Date(value);
                let y = date.getFullYear();
                let m = date.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                let d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                let h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                let minute = date.getMinutes();
                minute = minute < 10 ? ('0' + minute) : minute;
                return `${y}-${m}-${d} ${h}:${minute}`;
            },
            telFun(){
                let birthday = this.$props.birthday?this.$props.birthday:this.date;
                let equipment = JSON.parse(sessionStorage.getItem('equipment'));
                if((birthday&&this.name)||this.tel){
                    this.axiosTel({
                        data:{
                            company_id:equipment?equipment.sys_company_id:'',
                            phone:this.tel,
                            name:this.name,
                            birthday:birthday
                        },
                        _this:this,
                        url:this.$props.url,
                        query:this.$props.query,
                        token:this.$props.token
                    })
                }else{
                    if(this.$props.birthday){
                        Toast.fail('请输入手机号或者姓名！');
                    }else{
                        Toast.fail('请输入手机号或者姓名和出生年月！');
                    }
                }
            },
            mainFun(){
                let equipment = JSON.parse(sessionStorage.getItem('equipment'));
                if(equipment){
                    let _this = this;
                    let url = this.$props.url;
                    if(url && url!=='confirmAssess' && url!=='reportList'){
                        //语音
                        audioPaly.play(jiceover);
                    }
                    this.popupMain({
                        data:{
                            uuid:_this.uuid,
                            code:equipment.equipment_code
                        },
                        openFun:this.openFun
                    })
                }
            },
            formatter(type, val) {
                if (type === 'year') {
                    return val + '年';
                }
                if (type === 'month') {
                    return val + '月';
                }
                if (type === 'day') {
                    return val + '日';
                }
                return val;
            },
            openFun(equipment){
                let _this = this;
                if(this.codeFun()){
                    window.Lun = setInterval(function(){
                        _this.axiosLun({
                            data:{
                                uuid:_this.uuid,
                                code:equipment.code
                            },
                            _this:_this,
                            url:_this.$props.url,
                            query:_this.$props.query,
                            token:_this.$props.token,
                            callback:_this.$props.callback,
                            audio:_this.$props.audio
                        })
                    },3000);
                }
            },
            closeFun(){
                clearInterval(window.Lun);
            },
            ...mapActions('device',['axiosLun','popupMain','axiosTel'])
        }
    }
</script>
<style lang="scss" scoped> 
    @import './popup'; 
</style>